.create-pool-title {
  margin-bottom: 52px;
  h2 {
    font-size: 40px;
    color: #ffffff;
    font-weight: 200;
    margin: 30px 0 11px;
    font-family: Lexend-ExtraLight, sans-serif;
  }
  p {
    color: #ffffff;
    margin-bottom: 3px;
    font-family: Lexend-Regular, sans-serif;
  }
}

.create-pool-stepper {
  max-width: 1251px;
  min-height: 333px;
  border: 1px solid #e65634;
  border-radius: 20px;
  margin: 0 auto 80px auto;

  @media screen and (max-width: 1300px) {
    max-width: 734px;
  }

  @media screen and (max-width: 800px) {
    margin: 0 20px 80px 20px;
  }

  &_banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 40px;
    background: #e65634 0 0 no-repeat padding-box;
    margin: 0 auto;

    p {
      color: #ffffff;
      font-size: 14px;
    }
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    padding: 0 90px;

    @media screen and (max-width: 815px) {
      padding: 0 30px;
    }

    .create-pool-stepper-first {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
      margin-top: 35px;

      @media screen and (max-width: 1300px) {
        flex-direction: column;
      }

      .create-pool-stepper_select-options_item {
        width: 100%;
        height: 183px;
        cursor: pointer;
        position: relative;
        margin: 10px;

        &:hover {
          background: url('../../images/hover/Path_6_hover.svg');
        }

        @media screen and (max-width: 1300px) {
          height: 147px;
        }

        svg {
          width: 350px;
          height: 183px;

          @media screen and (max-width: 1300px) {
            width: 300px;
            height: 150px;
          }

          @media screen and (max-width: 530px) {
            width: 263px;
            height: 150px;
          }

          //@media screen and (max-width: 490px){
          //  width: 230px;
          //  height: 130px;
          //}
        }

        .create-pool-stepper_select-options_item_content {
          width: 95%;
          position: absolute;
          top: 21px;
          left: 50%;
          transform: translateX(-50%);

          h4 {
            width: 100%;
            font-size: 1.25rem;
            color: #ffffff;
            text-align: center;
            font-weight: 500;
            margin-bottom: 15px;

            @media screen and (max-width: 530px) {
              font-size: 1rem;
            }
          }

          .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 15px;

            .first-img {
              width: 58px;
              height: 58px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .middle-img {
              margin: 0 4px 0 3px;
            }

            .last-img {
              width: 58px;
              height: 58px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          p {
            font-size: 12px;
            color: #ffffff;
            font-family: Lexend-Regular, sans-serif;

            @media screen and (max-width: 1300px) {
              display: none;
            }
          }
        }
      }

      .create-pool-stepper_select-options_item_both {
        width: 100%;
        height: 183px;
        //margin-right: 17px;
        position: relative;
        margin: 10px;
        cursor: pointer;

        .img-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;

          .first-img {
            width: 58px;
            height: 58px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .middle-img {
            margin: 0 4px 0 3px;
          }

          .last-img {
            width: 58px;
            height: 58px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        &:hover {
          background: url('../../images/hover/Path_6_hover.svg');
        }

        @media screen and (max-width: 1300px) {
          height: 147px;
          margin-bottom: 50px;
        }

        svg {
          width: 350px;
          height: 183px;

          @media screen and (max-width: 1300px) {
            width: 300px;
            height: 150px;
          }
          @media screen and (max-width: 530px) {
            width: 263px;
            height: 150px;
          }
          //@media screen and (max-width: 490px){
          //  width: 230px;
          //  height: 130px;
          //}
        }

        h4 {
          width: 100%;
          font-size: 1.25rem;
          color: #ffffff;
          text-align: center;
          font-weight: 500;
          position: absolute;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);

          @media screen and (max-width: 1300px) {
            font-size: 1rem;
          }
        }

        p {
          width: 95%;
          font-size: 12px;
          color: #ffffff;
          position: absolute;
          top: 100px;
          left: 50%;
          transform: translateX(-50%);

          @media screen and (max-width: 530px) {
            font-size: 18px;
          }
        }
      }

      &.hidden {
        display: none;
        opacity: 0;
      }
    }

    .create-pool-stepper-second,
    .create-pool-stepper-third {
      margin-top: 56px;

      &_wrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 452px;
        color: #ffffff;

        @media screen and (max-width: 1300px) {
          flex-direction: column;
          width: 100%;
        }

        button {
          margin: 0;

          @media screen and (max-width: 1300px) {
            margin: 0 auto;
          }
        }
        .left {
          @media screen and (max-width: 1300px) {
            margin-bottom: 36px;
          }
        }

        .left,
        .right {
          p {
            font-size: 18px;
          }

          p:nth-child(2) {
            margin-bottom: 12px;
          }

          button {
            display: flex;
            align-items: center;

            img {
              width: 18px;
              margin-right: 10px;
            }
          }

          .wax-logo {
            margin-right: 10px;
          }
        }
      }

      .arrow {
        position: absolute;
        transform: matrix(0, 1, -1, 0, 0, 0);
        right: 13px;
      }

      &.hidden {
        display: none;
        opacity: 0;
      }
    }

    .create-pool-stepper-third {
      .count-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 34px;
        margin-bottom: 24px;

        @media screen and (max-width: 1300px) {
          flex-direction: column;
          margin: 0;
        }

        .pool-pricing-block,
        .assets-amount-block {
          width: 521px;
          height: 360px;
          background: #111122;
          border-radius: 5px;
          color: #ffffff;

          @media screen and (max-width: 1300px) {
            flex-direction: column;
            margin-bottom: 5px;
            width: 100%;
          }
        }

        .fees-amount {
          width: 120px;
          height: 53px;
          margin-bottom: 15px;
          position: relative;
          p {
            font-size: 12px;
            text-align: left;
            margin-bottom: 5px;
            color: #d8d8d8;
          }

          input[type='number'] {
            width: 95px;
            height: 33px;
            background: #000000;
            border: 1px solid #2b282c;
            color: #ffffff;
            font-size: 14px;
            padding: 0 10px 0 11px;
          }

          .coin {
            display: flex;
            align-items: center;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 28px;
            right: 20px;

            p {
              margin: 0 0 0 11px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .start-price {
          width: 440px;
          height: 53px;
          margin-bottom: 15px;
          position: relative;

          @media screen and (max-width: 630px) {
            width: 200px;
          }

          p {
            font-size: 12px;
            text-align: left;
            margin-bottom: 5px;
            color: #d8d8d8;
            font-family: Lexend-Regular, sans-serif;
          }

          input[type='number'] {
            width: 358px;
            height: 33px;
            background: #000000;
            border: 1px solid #2b282c;
            color: #ffffff;
            font-size: 14px;
            padding: 0 70px 0 11px;

            @media screen and (max-width: 630px) {
              width: 115px;
            }
          }

          .coin {
            display: flex;
            align-items: center;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 28px;
            right: 50px;

            p {
              margin: 0 0 0 11px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .bonding {
          margin-bottom: 15px;

          select {
            width: 174px;
            height: 33px;
            border: 1px solid #e65634;
            background: transparent;
            color: #ffffff;
            display: block;

            option {
              background: #130e1b;
            }
          }

          p {
            font-size: 12px;
            text-align: left;
            margin-bottom: 5px;
            color: #d8d8d8;
            font-family: Lexend-Regular, sans-serif;
          }
        }

        .delta-price {
          width: 440px;
          height: 53px;
          margin-bottom: 15px;
          position: relative;

          @media screen and (max-width: 630px) {
            width: 200px;
          }

          p {
            font-size: 12px;
            text-align: left;
            margin-bottom: 5px;
            color: #d8d8d8;
            font-family: Lexend-Regular, sans-serif;
          }

          input[type='number'] {
            width: 358px;
            height: 33px;
            background: #000000;
            border: 1px solid #2b282c;
            color: #ffffff;
            font-size: 14px;
            padding: 0 70px 0 11px;

            @media screen and (max-width: 630px) {
              width: 115px;
            }
          }

          .coin {
            display: flex;
            align-items: center;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 28px;
            right: 50px;

            p {
              margin: 0 0 0 11px;
            }

            .pricent {
              font-size: 18px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .pool-pricing-block {
        &_wrapper {
          margin: 0 38px;
        }

        &_title {
          margin: 11px 0;

          h6 {
            font-size: 18px;
            margin-bottom: 6px;
          }

          p {
            font-size: 12px;
            color: #d8d8d8;
            font-family: Lexend-Regular, sans-serif;
          }
        }
      }

      .assets-amount-block {
        &_wrapper {
          margin: 0 38px;
        }

        &_title {
          margin-top: 11px;
          margin-bottom: 32px;

          h6 {
            font-size: 18px;
            color: #ffffff;
            margin-bottom: 6px;
          }

          p {
            font-size: 12px;
            color: #ffffff;
            font-family: Lexend-Regular, sans-serif;
          }
        }

        &_amount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          font-family: Lexend-Regular, sans-serif;
          position: relative;

          @media screen and (max-width: 630px) {
            flex-direction: column;
          }
          &_collection {
            position: absolute;
            top: 8px;
            right: 12px;
            font-size: 12px;
            display: flex;
            align-items: center;

            &_sell {
              position: absolute;
              top: 9px;
              right: 12px;
              font-size: 12px;
              display: flex;
              align-items: center;
            }

            img {
              width: 20px;
              margin-right: 4px;
            }
          }

          p {
            font-size: 12px;

            @media screen and (max-width: 630px) {
              margin-bottom: 7px;
            }
          }

          input[type='number'] {
            width: 183px;
            height: 33px;
            background: #000000;
            border: 1px solid #2b282c;
            color: #ffffff;
            font-size: 14px;
            padding: 0 125px 0 11px;

            @media screen and (max-width: 630px) {
              width: 115px;
              padding: 0 20px 0 11px;
            }
          }
        }

        &_alert {
          text-align: center;
          margin-bottom: 61px;
          font-family: Lexend-Regular, sans-serif;

          @media screen and (max-width: 425px) {
            margin-bottom: 30px;
          }

          p {
            font-size: 12px;

            span {
              color: #e65634;
            }
          }
        }

        .info-block {
          margin: 15px 0;

          p {
            font-size: 12px;
          }
        }
        &_scroll {
          width: 227px;
          height: 99px;
          background: #000000;
          margin: 0 auto 0 auto;

          @media screen and (max-width: 1300px) {
            margin: 0 auto;
          }

          @media screen and (max-width: 425px) {
            width: 150px;
            height: 120px;
          }

          .css-1lon6cq {
            @media screen and (max-width: 425px) {
              width: 100px;
            }
          }
          &-wrapper {
            margin: 0 28px 0 28px;
            padding-top: 12px;

            .amount-nft {
              font-size: 12px;
              margin-bottom: 10px;
              font-family: Lexend-Regular, sans-serif;

              span {
                color: #e65634;
              }
            }
          }

          &_scroller {
            padding: 0 28px;
            margin-bottom: 17px;
          }
        }

        &_cost {
          font-family: Lexend-Regular, sans-serif;
          font-size: 12px;

          @media screen and (max-width: 425px) {
            font-size: 9px;
          }

          span {
            color: #e65634;
          }
        }
      }

      .diagram-block {
        max-width: 100%;
        padding: 0 70px;
        height: 360px;
        background: #111122;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_title {
          color: #ffffff;
          margin-top: 22px;
          margin-bottom: 18px;
          h4 {
            font-size: 18px;
            margin-bottom: 6px;

            @media screen and (max-width: 425px) {
              font-size: 16px;
            }
          }

          p {
            font-size: 12px;
            font-family: Lexend-Regular, sans-serif;
          }
        }
        //canvas{
        //  margin: 0 auto;
        //}
      }

      &.hidden {
        display: none;
        opacity: 0;
      }
    }

    .create-pool-stepper-fourth {
      h5 {
        margin: 15px 0 22px 0;
        font-size: 18px;
        color: #ffffff;
      }

      .finish-text {
        max-width: 495px;
        margin: 0 auto 25px auto;

        p {
          font-size: 14px;
          color: #ffffff;
          text-align: left;
          margin-bottom: 5px;

          @media screen and (max-width: 800px) {
          }

          span {
            color: #e65634;
          }
        }
      }

      .my-nfts-container {
        width: 855px;
        height: 450px;
        margin: 0 auto;
        border-top: 3px solid #e65634;
        //border: 1px solid red;

        &_list {
          width: 792px;
          height: 367px;
          //border: 1px solid red;
          margin: 0 auto;
          padding-top: 5px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          overflow: auto;

          .my-nft-item {
            width: 126px;
            height: 196px;
            background: #111122;
            border-radius: 10px;
            margin-right: 6px;
            margin-bottom: 10px;
            position: relative;
            cursor: pointer;

            &:hover {
              box-shadow: 0 0 5px #e65634;
            }

            .checked {
              position: absolute;
              top: 0;
              left: -5px;

              svg {
                stroke: #ffffff;
              }
            }

            .image {
              width: 85px;
              height: 120px;
              margin: 12px auto 4px auto;
              /*background: white;*/
              display: flex;
              align-items: center;

              img {
                width: 100%;
              }
            }

            .collection-block {
              width: 100%;
              height: 21px;
              background: #000000;
              color: #ffffff;
              font-size: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 4px;
            }

            .asset-id p {
              color: #d8d8d8;
              font-size: 10px;
              text-align: left;
              padding-left: 20px;
            }
          }
        }
      }

      .selected-nfts-list {
        width: 405px;
        max-height: 412px;
        overflow: auto;
        margin: 0 auto 20px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &_item {
          width: 126px;
          height: 196px;
          background: #111122;
          border-radius: 10px;
          margin-right: 6px;
          margin-bottom: 10px;
          position: relative;

          .image {
            width: 126px;
            height: 120px;
            margin: 12px auto 4px auto;
            //background: white;

            img {
              max-width: 100%;
              height: 100%;
            }
          }

          .collection-block {
            width: 100%;
            height: 21px;
            background: #000000;
            color: #ffffff;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 4px;
          }

          .asset-id p {
            color: #d8d8d8;
            font-size: 10px;
            text-align: left;
            padding-left: 20px;
          }
        }
      }

      button {
        margin-right: 0;
      }

      &.hidden {
        display: none;
        opacity: 0;
      }
    }

    .stepper-footer {
      margin-top: 51px;
      margin-bottom: 23px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      .disabled {
        border: 1px solid #999999;
        color: #999999;
        cursor: default;

        &:hover {
          background: none;
          color: #999999;
        }
      }

      button {
        margin: 0;
      }

      .prev,
      .next {
        position: absolute;
      }

      .prev {
        top: 0;
        left: 0;

        transform: matrix(-1, 0, 0, -1, 11, 7);

        @media screen and (max-width: 1300px) {
          top: 3px;
        }
      }

      .next {
        top: 8px;
        right: 11px;

        @media screen and (max-width: 1300px) {
          top: 6px;
        }
      }
    }
  }
}

// NFT
